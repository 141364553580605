.cl-vnavigation li.parent {
  max-height: 200vh;
  transition: max-height 0.4s, opacity 0.4s;
}

.cl-vnavigation li.parent.search-hidden {
  max-height: 0;
  opacity: 0;
  overflow: hidden;
}

.navigation-editor .field {
  margin-bottom: 20px;
}

.navigation-editor .field input,
.navigation-editor .field select {
  width: 100%;
}

@media screen and (min-width: 980px) { 
  .sidebarcontent {
    visibility: hidden;
    height: calc(100vh - 150px);
    overflow-x: scroll;
    position: fixed;
    left: 0;
    top: 64px;
    width: 280px;
  }

  .sidebar-logo {
    position: fixed;
    width: 280px;
  }
}
