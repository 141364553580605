#cl-wrapper {
  opacity:1;
  margin-left: 0;
}

.rdw-editor-wrapper div.rdw-link-modal {
  height: auto;
}

label {
  width: 100%;
}

.block-flat .content {
  padding: 15px 0 0;
}

.btn {
  margin-left: 0; 
  margin-right: 5px;
}

.block-flat.danger-box small {
  color: #ddd;
}

.cl-mcont {
  min-height: 100vh;
  height: 100%;
  padding: 15px 15px 15px 15px;
  padding-top: 80px;
  position: relative;
}

.block-flat {
  margin-bottom: 15px;
}

.flexwraps {
  display: flex;
  flex-wrap: wrap;
}

ul {
  padding: 0;
  list-style: none;
}

li {
  list-style-type: none;
  list-style: none;
}

.sorting-block-collapse {
  max-height: 0px;
  overflow: hidden;
}

#page-header {
  border-radius: 0;
  border-bottom: 1px solid #E0E4E8;
  vertical-align: middle;
  padding: 12px;
  position: fixed;
  left: 280px;
  top: 0;
  right: 0;
}

@media (max-width: 767px) {
  #page-header {
    bottom: 0;
    left: 0;
    right: 0;
    top: unset;
    border-top: 1px solid #E0E4E8;
    border-bottom: unset;
  }
}

#page-header h2 {
  margin: 0;
  line-height: 40px;
  display: inline-block;
  margin-right: 15px;
}

.inside-header {
  display: block;
  width: 100%;
  margin: 0px 15px 15px 15px;
  border-bottom: 3px solid #dadada;
  padding: 0px 5px 5px 5px;
}

.inside-header h2 {
  margin: 0;
  vertical-align: top;
  display: inline-block;
  margin-right: 15px;
}


.wysi-hide-item {
  display: none !important;
}

.list-item {
  border: 1px solid rgb(206, 206, 206);
  padding: 8px;
  line-height: 1.1em;
  display: flex;
  flex-direction: row;
  align-items: center;
  background-color: white;
}

.list-item + .list-item {
  margin-top: -1px;
}

ul .list-item:first-child {
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
}

ul .list-item:last-child {
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
}

.list-item.active {
  background-color:  #e7f3fb;
}

.list-item .flex-grow{
  flex: 1 1 auto;
}

.list-item .flex-fixed{
  flex: 0 0 auto;
}

.list-item .titles {
  padding: 0 8px;
}

.list-item .btn {
  margin-bottom: 0!important;
}

.list-item .icon {
  border-color: transparent;
  background-color: inherit;
  margin-right: 0;
}

.list-item .icon i {
  color: #b7b7b7;
  font-size: 15px;
}

.list-item .icon:hover i {
  color: #545454;
}

.list-item .title {
  font-size: 14px;
}

.list-item .sub-title {
  font-size: 10px;
  color: #757575;
}

.list-item .move {
  cursor: move;
}

.list-item.add-button {
  text-align: left;
  padding: 8px;
  width: 100%;
}

.no-margin-top {
  margin-top: 0;
}

.externalLink {
  color: black;
}

.externalLink:hover, .externalLink:focus {
  color: grey;
}


