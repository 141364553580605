.logotypemanager {
    min-height: 105px;
    position: relative;
}

.logotypemanager .mediaitem {
    width: 100px;
    height: 100px;
    margin: 0 5px 5px 0;
    background-size: contain;
    background-position: center center;
    background-repeat: no-repeat;
    display: inline-block;
    border: 1px solid grey;
    position: relative;
    vertical-align: top;
}

.logotypemanager .mediaitem .btn {
    position: absolute;
    right: 0;
    top: 0;
    margin: 0;
}

.logotypemanager .mediaitem.btn i {
    font-size: 32px;
}

.logotypemanager .fileinput {
    position: fixed;
    top: -99vh;
    left: -99vh;
}
