.Countdown{
    margin: 10px auto;
    padding-bottom: 20px;
    padding-top: 20px;
  }
  
  .Countdown-col{
    display: inline-block;
  }
  
  .Countdown-col-element{
    display: inline-block;
    margin: 0 20px;
    display: flex;
    flex-direction: column;
  }
  
  .Countdown-col-element strong{
    font-size: 50px;
    text-align: center !important;
  }

  .Countdown-col-element span {
      margin-top: 10px;
      text-align: center !important;
  }