.property-panel {
  padding: 10px;
  color: white;
}

.property-panel .hide {
  display: none;
}

.property-panel .show {
  display: block;
}

.property-panel__header {
  display: block;
  display: flex;
  flex-direction: row;
  margin-bottom: 10px;
  border-bottom: 1px solid white;
}

.property-panel__header__title {
  flex: 1 1 auto;
  overflow: hidden;
}

.property-panel__header__title h4 {
  margin-top: 0;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.property-panel__header__close {
  flex: 0 0 auto;
  padding: 0 0 0 10px;
  background-color: transparent;
  border: none;
  margin: 0 !important;
}

.property-panel__header__close .icon {
  font-size: 20px;
  color: white;
}

.property-panel .field {
  margin-bottom: 20px;
}

.property-panel textarea {
  width: 100%;
  max-width: 100%;
  height: 100px;
  resize: vertical;
}

.property-panel input,
.property-panel select {
  width: 100%;
  max-width: 100%;
}
